body {
  margin: 0;
  background-color: #000000;
    font-family: "Bellefair", serif;
    font-weight: 400;
    font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bgcw {
  background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
  border: 1px solid #D4BF7C;
  border-radius: 50%;
  font-size: 15px;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Baletta', sans-serif;
  width: 80px;
  padding: 30px 15px 10px 15px;
  transform: rotate(350deg);
}

@media only screen and (max-width: 600px) {
  .bgcwcontainer {
    position: absolute;
    top: 110px;
    left: 20px;
  }

  .bgcw {
    font-size: 10px;
    width: 65px;
    padding: 20px 10px 5px 10px;
    font-weight: 600;
  }
}

@media only screen and (min-width: 600px) {
  .bgcwcontainer {
    position: absolute;
    top: 130px;
    left: 50px;

  }

  .bgcw {
    font-size: 20px;
    width: 120px;
  }
}